@mixin row ($margin: 15px) {
    margin-right: -$margin;
    margin-left: -$margin;
    display: flex;
    flex-wrap: wrap; }


@mixin col ($count, $margin: 15px) {
    width: calc(100% / 12 * #{$count} - #{$margin} * 2);
    margin-left: $margin;
    margin-right: $margin; }


.wrapper {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px; }



html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }



article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } }

table {
  border-collapse: collapse;
  border-spacing: 0; }




@import 'partials/__header.sass';
@import 'partials/__member.sass';
@import 'partials/__send.sass';
@import 'partials/__news.sass';
@import 'partials/__footer.sass';
@import 'partials/jquery-ui.min.css';
@import 'partials/datepicker.min.css';
body * {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box; }
* {
  color: #4C4C66; }

@font-face {
  font-family: 'Futura Medium';
  src: url('/build/fonts/FuturaMediumC.eot');
  src: url('/build/fonts/FuturaMediumC.eot?#iefix') format('embedded-opentype'),
  url('/build/fonts/FuturaMediumC.woff') format('woff'),
  url('/build/fonts/FuturaMediumC.ttf') format('truetype') {}
  font-weight: normal;
  font-style: normal; }
button {
    cursor: pointer; }
.visible-visibility {
    opacity: 1;
    pointer-events: auto; }



.header__button {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    outline: 0;
    border: 0;
    height: 47px;
    width: 232px;
    background: linear-gradient(253.36deg, #53BA58 0%, #18B5D9 100%), #13B4E5;
    box-shadow: 0px 2px 16px rgba(0, 41, 54, 0.1);
    transition: .5s;
    position: relative;
    &::after {
      display: block;
      position: absolute;
      content: "ПРИСОЕДИНИТЬСЯ";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #FFFFFF;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: linear-gradient(102.01deg, #51BA5B 0%, #2BBADA 100%), #13B4E5;
      opacity: 0;
      transition: .5s;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
    &:hover {
      &::after {
        opacity: 1; } } }


.news__button {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    outline: 0;
    border: 0;
    height: 47px;
    width: 232px;
    display: block;
    margin: 0 auto;
    background: linear-gradient(253.36deg, #53BA58 0%, #18B5D9 100%), #13B4E5;
    box-shadow: 0px 2px 16px rgba(0, 41, 54, 0.1);
    transition: .5s;
    position: relative;
    &::after {
      display: block;
      position: absolute;
      content: "ОТПРАВИТЬ";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #FFFFFF;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: linear-gradient(102.01deg, #51BA5B 0%, #2BBADA 100%), #13B4E5;
      opacity: 0;
      transition: .5s;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
    &:hover {
      &::after {
        opacity: 1; } } }


input:valid, textarea:valid {
  background: #dfd; }
.jGrowl-message {
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF; }

.send__label {
	input:checked {
			& ~ .send__radio::after {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        content: "✔";
        color: #00577b;
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px; } } }



@media screen and (min-width: 931px) {
    .mobile-930 {
        display: none; } }
@media screen and (max-width: 930px) {
    .desktop-930 {
        display: none; } }
@media screen and (min-width: 769px) {
    .mobile {
        display: none; } }
@media screen and (max-width: 768px) {
    .desktop {
        display: none;



        span.request-form__error-message.error_send__name {
          width: 100%; } } }
