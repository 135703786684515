.send {
    color: white;
    background: linear-gradient(251deg, #53BA58 2.61%, rgba(55, 183, 150, 0) 100%), #13B4E5;
    padding-top: 64px;
    padding-bottom: 40px;
    &__wrapper {
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 20px; }
    &__title {
        max-width: 1021px;
        font-weight: 500;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #FFFF00;
        margin: 0 auto 12px; }
    &__container {
        max-width: 640px;
        padding: 20px;
        margin: 0 auto;
        padding-top: 28px; }
    &__personal-data {
        margin-bottom: 40px; }
    &__base-info {
        position: relative;
        &__sanding {
            display: none;
            position: absolute;
            top: calc(0% - 2%);
            left: calc(0% - 2%);
            width: 104%;
            height: 104%;
            background: rgba(19, 180, 229, 0.7);
            border-radius: 10px;
            padding: 0 15%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img {
                max-width: 100%; }
            .sanding__text {
                display: flex;
                align-items: center;
                margin-top: 20px;
                p {
                    color: #ff0;
                    margin: 0;
                    font-size: 26px;
                    font-weight: 500;
                    margin-right: 10px; }
                img {
                    max-height: 25px; } }
            &_active {
                display: flex; } }
        p {
            color: white;
            margin-bottom: 8px;
            font-weight: normal;
            font-size: 18px; }
        label {
            color: white;
            margin: 24px 0;
            display: block;
            position: relative;
            input {
                border: 0;
                outline: 0;
                padding: 14px;
                width: 100%;
                font-size: 18px;
                &:focus + span {  //анимация 3 инпутов при фокусе
                    width: 100%; }
                &:valid + span {  //анимация 3 инпутов при фокусе
                    width: 100%; } }
            input.send__label-checkbox {
                width: 1%; } }
        >div:first-child {   //анимация 3 инпутов при фокусе
            span {
                position: absolute;
                width: 100%;
                bottom: -2px;
                left: 0;
                background: #53ba58;
                height: 4px;
                display: block;
                transition: .5s; } } }


    &__form__special-label {
        display: block;
        position: relative;
        textarea {
            min-width: 100%;
            max-width: 100%;
            outline: 0;
            border: 0;
            height: 90px;
            padding: 10px;
            font-weight: 400;
            font-size: 18px;
            &:focus + span {  //анимация 3 инпутов при фокусе
                width: 100%; }
            &:valid + span {  //анимация 3 инпутов при фокусе
                width: 100%; } }
        span {
            position: absolute;
            width: 100%;
            bottom: -4px;
            left: 0;
            background: #53ba58;
            height: 4px;
            display: block;
            transition: .5s; } } }
.request-form__error-message.error_send__text {
    bottom: -2px;
    &__choice {
        position: relative;
        margin-bottom: 20px;
        .send__select-arrow {
            position: absolute;
            right: 10px;
            top: 48px;
            z-index: 1;
            pointer-events: none;
            box-sizing: border-box;
            transition: transform 0.5s;
            &_rotate {
                transform: rotate(-180deg); } }
        select {
            border: 0;
            outline: 0;
            padding: 16px;
            width: 100%;
            font-size: 18px;
            background: white;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            option {
                width: 100%;
                border: 0;
                outline: 0; } } } }

.request-form__error-message.error_send__name.error {
    span.error {
        position: static;
        margin-top: 5px;
        background: none; } }
.request-form__error-message.error_send__phone.error {
    span.error {
        position: static;
        margin-top: 5px;
        background: none; } }
.request-form__error-message.error_send__email.error {
     span.error {
        position: static;
        margin-top: 5px;
        background: none; } }
.request-form__error-message.error_business.error {
    span.error {
        position: static;
        margin-top: 5px;
        background: none; } }
.request-form__error-message.error_send__text.error {
     span.error {
        position: static;
        margin-top: 5px;
        background: none; } }


//стилизация радиобатонов


.send__label {
	display: block;
	position: relative;
	padding-left: 27px;
	margin-bottom: 16px;
	cursor: pointer;
	font-size: 18px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer {
        outline: none;
        border: none; }
		&:checked {
			& ~ .send__radio {
				background-color: #FFFF00;
 } } } }				// transition: .5s

.send__radio {
	position: absolute;
	top: -3px;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: white; }

.radio-title {
    margin-right: 24px;
    max-width: 150px;
    display: block; }
.send__label-agree {
    margin: 0 auto;
    // max-width: 463px
    margin-bottom: 33px; }
.send__submit {
    padding: 17px 58px;
    background: rgba(255, 255, 0, 1);
    color: #00577B;
    border: 0;
    outline: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin: 0 auto;
    display: block;
    transition: .3s;
    &:hover {
        background: rgba(255, 255, 0, 0.8); } }




@media (max-width: 1200px) {
    .send__title {
        max-width: 760px;
        padding: 0px 15px; } }
@media (max-width: 768px) {
    .send {
        padding-top: 40px;
        padding-bottom: 20px;
        &__base-info label {
            margin: 16px 0; }
        &__radio {
            top: 0; }
        &__personal-data {
            margin-bottom: 24px; }
        &__title {
            font-size: 32px;
            line-height: 37px;
            padding: 0 13px;
            margin-bottom: 32px; } } }
@media (max-width: 768px) {
    .send {
        &__choice::after {
            top: 43px; }
        p, select, option, input, textarea {
            font-size: 18px !important;
            line-height: 21px; }
        &__container {
            padding: 0px 0 20px; } } }
@media (max-width: 420px) {
    .send__base-info label input {
        padding: 11px; }
    .send__submit {
            margin: 0;
            width: 100%; } }

.request-form__error-message.error_agreement {
    &.active {
        display:none {} }

















    // &__base-info
    //     display: flex
    //     flex-wrap: wrap
    //     font-weight: normal
    //     font-size: 18px
    //     line-height: 21px
    //     margin-bottom: 20px
    //     label
    //         margin: 20px
    //         p
 }    //             padding-bottom: 8px
//         input
//             width: 318px
//             height: 45px
//             padding: 10px
//             font-size: 18px
//             border: 0
//             outline: 0
//     &__date
//         position: relative
//         &::before
//             content: ""
//             display: block
//             position: absolute
//             right: 12px
//             top: 42px
//             width: 21px
//             height: 21px
//             background: url('../img/send/date.png') no-repeat
//     &__choice
//         width: 100%
//         padding: 0 20px
//         position: relative
//         display: inline-block
//         margin-bottom: 20px
//         &::after
//             content: ""
//             background: url('../img/send/vector.png')
//             padding: 0 8px
//             font-size: 12px
//             position: absolute
//             right: 34px
//             top: 49px
//             z-index: 1
//             text-align: center
//             width: 21px
//             height: 11px
//             pointer-events: none
//             box-sizing: border-box
//         p
//             font-size: 18px
//             line-height: 21px
//             padding-bottom: 8px
//         select
//             width: 100%
//             height: 45px
//             border: 0
//             outline: 0
//             background: white
//             -webkit-appearance: none
//             -moz-appearance: none
//             appearance: none
//             option
//                 color: black
//                 font-family: Roboto
//                 font-weight: normal
//                 font-size: 18px
//                 line-height: 21px
//                 padding-bottom: 8px
//     label
//         display: block

//     &__info-radio
//         display: flex
//         justify-content: space-between
//         width: 96%
//         margin: 0 auto 49px
//         padding-top: 6px
//         font-weight: normal
//         font-size: 18px
//     &__status
//         display: flex
//     &__status-class, &__finance-class
//         display: flex
//         padding-top: 17px
//     &__years
//         display: none
//         p
//             margin-bottom: 8px
//         input
//             width: 318px
//             height: 45px
//             padding: 10px
//             font-size: 18px
//             border: 0
//             outline: 0
//  // &__submit
//  //     width: 191px
//  //     height: 47px
//  //     background: #FFFF00
//  //     border: 0
//  //     outline: 0
//  //     font-weight: 500
//  //     font-size: 12px
//  //     line-height: 14px
//  //     text-align: center
//  //     margin: 0 auto
//  //     display: block





