.header {
    padding: 50px 0 136px;
    position: relative;
    &::before {
        content: "";
        display: block;
        position: absolute;
        background: url('/build/img/header/Frame.png');
        background-repeat: no-repeat;
        height: 100%;
        width: 42%;
        top: 0;
        z-index: -1; }
    &__wrapper {
        margin: 0 auto;
        max-width: 1240px;
        padding: 0 20px; }
    &__logos {
        text-align: right;
        width: 100%;
        margin-bottom: 20px;
        display: flex; }
    &__centrinvest {
        margin: 0 40px 20px 0;
        width: 58%;
        text-align: right; }
    &__container {
        display: flex;
        justify-content: space-between; }
    &__img {
        @include col(8, 20px);
        flex-basis: 58%;
        margin-bottom: -2px;
        img {
            max-width: 100%;
            max-height: 484px; } }
    &__info {
        @include col(4, 20px);
        flex-basis: 38%;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
    &__title {
        max-width: 272px;
        font-weight: 500;
        font-size: 36px;
        line-height: 55px;
        color: #13B4E5;
        margin-bottom: 20px; }
    &__text {
        margin-bottom: 38px;
        max-width: 425px;
        p {
            font-weight: normal;
            font-size: 20px;
            line-height: 26px; } } }
.subheader {
    padding: 35px 0 0; }

@media (max-width: 1200px) {
    .header__logos img {
        max-width: 154px;
        margin: 0 0 0 2%; }
    .header__title {
        font-size: 36px;
        line-height: 43px; }
    .header__img {
        margin-right: 20px; }
    .header__text {
        p {
            font-size: 19px; } } }
@media (max-width: 992px) {
    .header__container {
        width: 100%;
        margin: 0 auto; }
    .header {
        &__img, &__info {
            width: 100%; } } }
@media screen and (max-width: 930px) {
    .header {
        padding: 11px 0 49px;
        &::before {
            width: 60%; }
        &__container {
            width: calc(100% + 20px); }
        &__logos {
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 9px; }
        &__centrinvest {
            width: auto;
            margin: 0 10px 0 0;
            a img {
                max-height: 25px; } }
        &__img {
            flex-basis: 65%;
            margin: 0 0 0 -20px; }
        &__title {
            max-width: 242px;
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 24px; }
        &__text {
            margin-bottom: 24px;
            max-width: 100%;
            p {
                font-size: 16px;
                line-height: 19px; } }
        &__link {
            display: block;
            text-align: center; }
        &__button {
            width: 100%; } } }
@media screen and (max-width: 768px) {
    .header__img {
            flex-basis: 100%; } }
