.footer {
    background: linear-gradient(266.95deg, rgba(79, 186, 97, 0.7) 0%, rgba(36, 182, 189, 0) 83.22%), #13B4E4;
    padding: 31px 0;
    &__wrapper {
        max-width: 1240px;
        padding: 0 20px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between; }
    span ,a {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        text-decoration: none; }
    &__mobile {
        display: none; } }
@media screen and (max-width: 768px) {
    .footer {
        span:nth-of-type(2) {
            font-weight: normal;
            font-size: 12px; } } }
@media screen and (max-width: 540px) {
    .footer {
        padding: 24px 0;
        &__wrapper {
            flex-direction: column; }
        span {
            display: block;
            &:first-child {
                margin-bottom: 8px; }
            &:last-child {
                display: none; } }
        &__mobile {
            display: block; } } }
@media screen and (max-width: 340px) {
    .footer {
        span ,a {
            font-size: 16px; } } }
