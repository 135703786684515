.member {
    padding: 98px 0 39px;
    &__wrapper {
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px; }
    &__title {
        font-weight: 500;
        font-size: 36px;
        line-height: 42px;
        color: #13B4E5;
        text-align: center;
        margin-bottom: 70px;
        &:first-child {
            color: #3AAA35; } }
    &__container {
        display: flex;
        flex-wrap: wrap; }
    &__item {
        width: 50%;
        display: flex;
        margin-bottom: 60px; }
    &__img {
        width: 30%;
        padding-right: 15px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        &-svg {
            width: 100%; }
        a {
            display: block;
            img {
                width: 100%;
                // max-height: 111px
                @media screen and (max-width: 768px) {
                    max-width: 80px; } } } }
    &__info {
        width: 70%;
        p {
            width: 70%; } }
    &__name {
        margin-bottom: 16px;
        a, p {
            display: block;
            width: 70%;
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            letter-spacing: 0.05em;
            text-decoration: none; } }
    &__text {
        p {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em; }
        margin-bottom: 8px; }
    &__link {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        text-decoration: underline;
        color: #4C4C66; }
    &-all {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
        margin: 0 auto;
        padding: 10px 0;
        p {
            text-transform: uppercase;
            margin-right: 10px;
            font-size: 14px;
            color: #13b4e5; }
        img {
            transition: all 0.2s linear; }
        &__arrow_up {
            transition: all 0.2s linear;
            transform: rotate(180deg); } } }

@media screen and (max-width: 930px) {
    .member {
        padding: 71px 0;
        &__title {
            font-size: 32px;
            line-height: 37px;
            margin-bottom: 32px; } } }
@media (max-width: 768px) {
    .member {
        &__item {
            width: 100%;
            box-shadow: 0 2px 24px rgba(0,94,77,.08);
            padding: 20px;
            margin-bottom: 25px; }
        &__item:nth-of-type(n+4) {
            display: none; }
        &__info {
            p, a {
                width: 100%;
                font-size: 18px;
                line-height: 20px; } }
        &__img {
            a {
                height: 100%;
                text-align: center; } } } }
@media (max-width: 600px) {
    .member__item {
        img {
            width: 100%; } } }
@media (max-width: 420px) {
    .member {
        //     box-shadow: none
        //     padding: 0
        //     width: 100%
        //     margin: 10px auto
        //     display: flex !important
        //     flex-direction: column
        //     align-items: center
        //     justify-content: center
        //     min-height: 188px
        &__text p {
            display: none; }
        // &__img
        //     width: 100%
        //     padding: 0
        //     margin-bottom: 10px
        // &__info
        //     width: 100%
        //     margin: 0
        &__name {
            margin: 0;
            p {
                font-size: 18px;
                line-height: 21px; } }
        &__link {
            display: none; } } }


