.news__cite-info, .news__cite-text, .news__cite-author {
    transition: all .5s; }

.news {
    padding: 85px 0 113px;
    transition: all .5s;
    &__slider {
        max-width: 960px;
        margin: 0 auto; }
    .slider-switches {
        max-width: 1040px;
        margin: 0 auto;
        position: relative;
        &__button {
            position: absolute;
            left: 0;
            top: 109px;
            width: calc(100%);
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 700px) {
                display: none; } }
        &__prev, &__next {
            z-index: 9;
            cursor: pointer; } }
    &__cite {
        display: flex;
        align-items: flex-start;
        max-width: 960px;
        margin: 0 auto 91px;
        position: relative;
        padding: 30px 50px 0;
        &-img {
            @include col(3, 20px);
            position: relative;
            height: 188px;
            width: 167px;
            position: relative;
            display: flex;
            align-items: flex-end;
            &_double {
                margin: 0;
                &-first {
                    margin-right: 18px; } }
            img {
                max-height: 100%;
                max-width: 100%;
                border-radius: 16px;
                padding: 8px 3px 3px; }
            &::after {
                content: '';
                background: url(/build/img/news/ramka.svg) no-repeat;
                background-size: contain;
                background-position: bottom;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%; } }
        &-info {
            @include col(9, 20px);
            background: #ffffff;
            box-shadow: 6px 4px 40px 0 rgba(0, 100, 82, 0.16);
            border-radius: 10px;
            padding: 29px 21px 16px 33px;
            position: relative;
            @media screen and (min-width: 851px) {
                height: 128px; }
            @media screen and (max-width: 850px) {
                @include col(12, 20px);
                margin: 0 30px 0 20px; }
            &_double {
                max-width: 461px; }
            &::before {
                content: "";
                display: block;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                background: #ffffff;
                border-radius: 10px;
                z-index: -1;
                transition: all .5s; }
            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                background: linear-gradient(253.55deg, #4FB960 -1.67%, #1CB5D0 87.47%), #13B4E4;
                box-shadow: 0px 2px 15px rgba(109, 124, 147, 0.2);
                border-radius: 10px;
                right: -24px;
                bottom: -24px;
                z-index: -2;
                transition: all .5s; }
            p {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.05em;
                color: #4C4C66;
                &:last-child {
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0.05em;
                    text-align: right;
                    position: absolute;
                    bottom: 16px;
                    right: 32px;
                    @media screen and (max-width: 768px) {
                        right: 24px; } } } }

        &:hover {
            .news__cite-info {
                transform: scale(1.05);
                box-shadow: 6px 0px 48px 0 rgba(0, 100, 82, 0.24);
                &:after {
                    transform: scale(0.95); } } } }

    &__title {
        font-size: 36px;
        line-height: 42px;
        font-weight: 500;
        text-align: center;
        // letter-spacing: 0.10em
        color: #13B4E5;
        margin-bottom: 64px; }

    &__block {
        max-width: 1240px;
        padding: 20px;
        margin: 0 auto 92px; }

    &__cards {
        display: grid;
        grid-template-columns: 64% 34%;
        grid-template-rows: auto auto;
        grid-column-gap: 24px;
        grid-row-gap: 22px; }

    &__card {
        display: block;
        text-decoration: none;
        padding: 32px 96px 32px 40px;
        background: #FFFFFF;
        box-shadow: 0px 2px 24px rgba(0, 94, 77, 0.08);
        letter-spacing: 0.05em;
        color: #4C4C66;
        transition: all .5s;
        &-time {
            margin-bottom: 23px;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px; }
        &-title {
            font-weight: bold;
            font-size: 22px;
            line-height: 26px; }
        &-descr {
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            margin-top: 16px; }
        &:hover {
            box-shadow: 0px 2px 36px rgba(1, 62, 51, 0.24); }
        &:last-child {
            grid-column: 2;
            grid-row: 1 / 3;
            padding: 32px 40px;
            cursor: auto;
            @media screen and (min-width: 1001px) {
                .news__card-time {
                    margin-bottom: 16px; }
                .news__card-descr {
                    display: none; }
                .news__card-title {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 0; } }
            &:hover {
                box-shadow: 0px 2px 24px rgba(1, 62, 51, 0.24); }
            .news__card-subcard {
                display: block;
                cursor: pointer;
                text-decoration: none;
                @media screen and (min-width: 1001px) {
                    margin-bottom: 32px; } } }
        &:first-child {
            @media screen and (min-width: 1001px) {
                .news__card-title {
                    font-size: 28px;
                    line-height: 33px; }
                .news__card-descr {
                    display: none; } } } }

    &__form-wrapper {
        max-width: 640px;
        padding: 0 20px;
        margin: 0 auto; }

    &__title-form {
        max-width: 574px;
        font-weight: 500;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        margin: 0 auto 60px;
        color: #13B4E5; }

    &__form {
        margin-bottom: 54px;
        label {
            display: block;
            margin: 15px 0;
            p {
                margin-bottom: 8px;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px; }
            input {
                width: 100%;
                height: 45px;
                padding: 10px;
                border: 0;
                outline: 0;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                background: #F4F4F4;
                &:valid {
                    background: #dfd; } }
            textarea {
                resize: both;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                background: #F4F4F4;
                resize: both;
                max-width: 100%;
                min-width: 100%;
                height: 162px;
                border: 0;
                outline: 0;
                padding: 10px;
                &:valid {
                    background: #dfd; } } }
        .news__textarea {
            margin-top: 30px;
            @media screen and (max-width: 768px) {
                margin-bottom: 24px; } } } }






@media screen and (max-width: 1000px) {
  .news {

    &__cards {
      display: block; }

    &__card {
      margin-bottom: 24px;
      padding: 32px 40px;
      &:hover {
        box-shadow: 0px 2px 24px rgba(0, 94, 77, 0.08); }
      &:last-child {
          box-shadow: none;
          margin-bottom: 0;
          padding: 0;
          &:hover {
            box-shadow: none; }
          .news__card-subcard {
            box-shadow: 0px 2px 24px rgba(0, 94, 77, 0.08);
            margin-bottom: 24px;
            padding: 32px 40px;
            &:last-child {
                margin-bottom: 0; } } } } } }


@media (max-width: 850px) {
    .news {
        padding-top: 40px;
        &__container-left {
            width: 60%; }
        &__container-right {
            width: 40%; }
        &__title-form {
            margin-bottom: 20px; }
        &__cite {
            max-width: 633px;
            margin: 0 auto;
            min-height: 258px;
            padding: 0;
            justify-content: center;
            &:hover {
                .news__cite-info {
                    transform: none; } }
            &-img {
                display: none; }
            &-info::after {
                bottom: -36px;
                right: -10px; } } }
    .news__cite-info p:last-child {
        padding: 0; }
    .slider-switches__button {
        top: 113px; } }


@media (max-width: 780px) {
    .news {
        padding-bottom: 0;
        &__block {
            padding: 70px 20px 0; }
        &__container {
            flex-direction: column;
            min-height: auto; }
        &__container-left {
            display: none; }
        &__container-right {
            width: 100%;
            padding: 0 0 40px; }
        &__title, &__title-form {
            font-size: 32px;
            line-height: 37px;
            margin-bottom: 40px; }
        &__item {
            padding: 21px 32px;
            &-time {
                font-weight: 300;
                font-size: 14px;
                line-height: 16px; }
            &-title-3 {
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                margin: 0; }
            &-body {
                margin: 0 0 24px 0;
                &:last-child {
                    margin: 0; } } } } }

@media screen and (max-width: 768px) {
  .news {
    &__cards {
      background: #FFFFFF;
      box-shadow: 0px 2px 24px rgba(0, 94, 77, 0.08);
      padding: 24px 32px; }

    &__card, &__card:hover, &__card:last-child, &__card:last-child:hover, &__card:last-child .news__card-subcard {
      box-shadow: none;
      padding: 0; }

    &__card-time {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 16px; }

    &__card-title {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px; }

    &__card-descr {
      display: none; } } }

@media (max-width: 660px) {
    .news__cite {
        max-width: 430px; }
    .news__cite-info {
        height: auto;
        p {
            font-size: 18px;
            line-height: 20px;
            &:last-child {
                font-weight: normal; } } } }

@media (max-width: 450px) {
    .news__cite {
        padding-top: 5px; }
    .news__cite-info p {
        font-size: 18px;
        line-height: 20px; }
    .news__cite-info {
        padding: 24px 24px 16px 16px; }
    .news__cite {
            padding: 20px 0 40px;
            &-info p {
                margin-bottom: 10px;
                &:last-child {
                    margin: 0; } } } }

@media (max-width: 494px) {
    .news {
        &__button {
            margin: 0;
            width: 100%; } }
    .request-form__error-message.error_business.error span.error, .request-form__error-message.error_send__email.error span.error, .request-form__error-message.error_send__name.error span.error, .request-form__error-message.error_send__phone.error span.error, .request-form__error-message.error_send__text.error span.error {
        font-size: 10px; } }
@media (max-width: 420px) {
    .request-form__error-message.error_business.error span.error, .request-form__error-message.error_send__email.error span.error, .request-form__error-message.error_send__name.error span.error, .request-form__error-message.error_send__phone.error span.error, .request-form__error-message.error_send__text.error span.error {
        font-size: 9px; } }
@media (max-width: 280px) {
    .request-form__error-message.error_business.error span.error, .request-form__error-message.error_send__email.error span.error, .request-form__error-message.error_send__name.error span.error, .request-form__error-message.error_send__phone.error span.error, .request-form__error-message.error_send__text.error span.error {
        font-size: 8px; } }

